import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from "react-redux";
import {createStore, applyMiddleware, compose} from "redux";
import reducers from './reducers';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';

import thunk from "redux-thunk";
import { BrowserRouter as Router} from 'react-router-dom';
const store = createStore(reducers, compose(applyMiddleware(thunk)))

// ReactDOM.hydrate(
//   <Provider store= {store}>
//     <React.StrictMode>
//       <Router>
//       <App />
//       </Router>
      
//     </React.StrictMode>
//   </Provider>, document.getElementById('root')
// )
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store= {store}>
    <React.StrictMode>
      <Router>
      <App />
      </Router>
      
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
